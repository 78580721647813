import React from "react"
import Seo from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"

const PrivacyPolicy = () => {
  return (
    <section className="py-[4.375rem]">
      <div className="container">
        <div className="title-sec m-auto w-full">
          <h1 className="lg:text-[2.5rem] md:text-[2.3rem] text-[2rem] pb-4 font-blancotrialbold lg:leading-[3.75rem] md:leading-[3rem] leading-[2.75rem] text-center">
            Privacy Policy
            <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
          </h1>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz Infoweb approach to data protection and privacy is more than
            a legal compliance. It is a core part of both Techuz's business
            value proposition and its culture, as well as global in scope.
            Protecting your privacy is important to us and our employees. We
            believe confidentiality and protection of customer information to be
            one of our most fundamental responsibilities.
          </p>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            We do not use your contact information like your name and email
            address for personal use. We use this contact information from the
            registration form to send you information about our company. You can
            always opt-out of receiving future emails by choosing the
            unsubscribe option in the registration form.
          </p>
          <h3 className="text-[1.5rem] pb-1 font-blancotrialbold leading-[2.5rem] text-left">
            Personal information we collect:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Personal information is the data that is associated with your name
            or personal identity. The personal information you provide us may
            include name, e-mail address, phone numbers and project details. We,
            at Techuz Infoweb, may use your personal information to understand
            your needs and interests better and to provide you with better
            services. We do not sell, rent or lease any individual's/company's
            personal information or lists of email addresses to third parties
            for commercial and/or marketing purposes.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Notification of Changes:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            If we decide to change our privacy policy, we will post those
            changes on our website so that you will always be aware of what
            information we collect and how we use it.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Web Servers:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz Infoweb automatically receives and records information from
            your browser via our servers, including your IP address, the time
            and information regarding the page you requested, etc.
          </p>
          <h3 className="text-[1.5rem] pb-4 font-blancotrialbold leading-[2.5rem] text-left">
            Copyrights & Trademarks:
          </h3>
          <p className="text-base leading-[1.7rem] text-left text-grey pb-6">
            Techuz Infoweb ensures that the material on this website is accurate
            and current. Techuz Infoweb has independently developed all the
            content presented on its website. The copyright for content provided
            by Techuz Infoweb will remain the property of Techuz Infoweb and the
            company does not allow its re-printing on any medium without a prior
            written permission.
          </p>
        </div>
      </div>
    </section>
  )
}
export const Head = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <Seo
      title="Privacy Policy"
      description="Your privacy is important to us. Techuz does not share your information with any third party or any another website, except to the extent necessary to complete your request."
      canonical={`${siteUrl}${location.pathname}`}
    />
  )
}
export default PrivacyPolicy
